import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/browser";

Sentry.init({
	dsn: "https://03f3cb22d1bb0be5fd6b16f3b481412d@o4507339220975616.ingest.de.sentry.io/4507339222417488",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],


	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions

	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [ /\//, /\/graphql/ ],

	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
	<App />,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
