import { Button, Checkbox, Form, InfoBanner, LoadingOverlay, Modal, WarningBanner, WithLabel } from '@atrocit/scl';
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import ButtonArea from '../lib/forms/ButtonArea';
import ValidatedButton from '../lib/forms/ValidatedButton';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import UserSelectField from '../lib/selectfields/UserSelectField';
import { FormattedDate, FormattedNumber } from 'react-intl';
import AbsenceStatusIndicator from './AbsenceStatusIndicator';
import { AbsenceType } from './AbsenceType';
import SelectField from '../lib/selectfields/SelectField';
import { useConfiguration } from '../../hooks/useConfiguration';

export default function AbsenceFinancialApprovalModal({ absenceId, onClose }) {
	const cfg = useConfiguration();
	if (cfg != null && !cfg.dualAbsenceApproval) onClose();

	const [ user, setUser ] = useState(null);
	const [ startDate, setStartDate ] = useState(DateTime.now().startOf('day').startOf('day'));
	const [ endDate, setEndDate ] = useState(DateTime.now().startOf('day').endOf('day'));
	const [ cepaLinesForWeekend, setCepaLinesForWeekend ] = useState(false);
	const [ absenceStatus, setAbsenceStatus ] = useState('REQUESTED');
	const [ absenceType, setAbsenceType ] = useState('');
	const [ remark, setRemark ] = useState('');
	const [ workerRequested, setWorkerRequested ] = useState(false);
	const [ financialApprovalStatus, setFinancialApprovalStatus ] = useState('UNDECIDED');

	const [ getAbsence, { loadingQuery } ] = useLazyQuery(gql`query Query($id: Int!) {
		absence(id: $id) {
			id, 
			user { 
				id 
			},
			start,
			end,
			cepaLinesForWeekend,
			absenceStatus,
			absenceType,
			remark,
			workerRequested,
			financialApprovalStatus,
		}
	}`);

	const [ setAbsenceFinancialApprovalStatusMutation, { loading: loadingMutation } ] = useMutation(gql`mutation Mutation($absenceId: Int!, $weekendLines: Boolean!, $financialApprovalStatus: AbsenceFinancialApprovalStatus!) {
		changeAbsenceFinancialApprovalStatus(absenceId: $absenceId, weekendLines: $weekendLines, financialApprovalStatus: $financialApprovalStatus) { id }
    }`);

	const savingRecordTotalsQuery = useQuery(gql`query Query($userId: Int) {
        savingRecordTotals(userId: $userId, includeUnapproved: true) {
            wageTotal,
            premiumTotal
        }
    }`, { variables: { userId: user } });
	const savingRecordTotals = savingRecordTotalsQuery?.data?.savingRecordTotals ?? {};

	useEffect(() => {
		if (absenceId == null) return;
		getAbsence({ variables: { id: absenceId } })
			.then(({ data }) => {
				const absence = data?.absence;
				setUser(absence.user?.id);
				setAbsenceStatus(absence?.absenceStatus);
				setStartDate(DateTime.fromISO(absence.start));
				setEndDate(DateTime.fromISO(absence.end));
				setCepaLinesForWeekend(absence.cepaLinesForWeekend);
				setAbsenceType(absence.absenceType);
				setRemark(absence.remark);
				setWorkerRequested(absence.workerRequested);
				setFinancialApprovalStatus(absence.financialApprovalStatus);
			});
	}, [ absenceId ]);

	return <Modal isOpen={true} title={'HR absentie goedkeuren'} onRequestClose={onClose}>
		<Form onSubmit={() => {
			if (financialApprovalStatus != null) {
				setAbsenceFinancialApprovalStatusMutation({
					variables: {
						absenceId: absenceId,
						weekendLines: cepaLinesForWeekend,
						financialApprovalStatus: financialApprovalStatus,
					},
				}).finally(onClose);
			}
		}}>
			{(loadingQuery || loadingMutation) && <LoadingOverlay />}

			<WithLabel label="Wie">
				<UserSelectField onChange={setUser} value={user} disabled={true} />
			</WithLabel>

			<WithLabel label="Periode">
				<span style={{ whiteSpace: 'nowrap' }}><FormattedDate value={startDate.toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="long"/></span>
				{!startDate.hasSame(endDate, 'day') && <>
					<span style={{ margin: '0 var(--u-4)', color: 'var(--col-grey-500)', fontSize: 'var(--fs-9)' }}> t/m </span>
					<span style={{ whiteSpace: 'nowrap' }}><FormattedDate value={endDate.toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="long"/></span>
				</>}
			</WithLabel>

			<WithLabel label="Toelichting">
				<span>{remark.trim() || '-'}</span>
			</WithLabel>

			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<h2><AbsenceType type={absenceType} /></h2>
				<AbsenceStatusIndicator status={absenceStatus} />
			</div>

			<br />
			<hr />
			<br />

			<WithLabel label="CEPA-regels voor weekenden">
				<Checkbox onChange={setCepaLinesForWeekend} value={cepaLinesForWeekend} />
			</WithLabel>

			<WithLabel label="HR-goedkeuringsstatus">
				<SelectField
					options={[
						{ value: 'APPROVED', label: <>&nbsp;<span className="fa fa-check-circle green" />&nbsp;Goedgekeurd</> },
						{ value: 'DENIED', label: <>&nbsp;<span className="fa fa-times-circle red" />&nbsp;Afgewezen</> },
						{ value: 'UNDECIDED', label: <>&nbsp;<span className="fa fa-question-circle grey" />&nbsp;Onbeslist</> },
					]}
					onChange={newValue => setFinancialApprovalStatus(newValue?.value)}
					value={financialApprovalStatus} />
			</WithLabel>

			{absenceType == 'SAVINGS_RECORD' && <div style={{ maxWidth: '80vh' }}>
				{((savingRecordTotals?.wageTotal + savingRecordTotals?.premiumTotal) <= 300) && <div style={{ width: '100%', maxWidth: '500px' }}>
					<WarningBanner>
						Het saldo van deze persoon is laag<br />
						<table>
							<colgroup>
								<col width="1" />
								<col width="*" />
							</colgroup>
							<tbody>
								<tr>
									<td style={{ paddingRight: '8px' }}><b>Loon:</b></td>
									<td style={{ textAlign: 'right' }}><FormattedNumber value={savingRecordTotals?.wageTotal || 0} style="currency" currency="EUR" /></td>
								</tr>
								<tr>
									<td style={{ paddingRight: '8px' }}><b>Premie:</b></td>
									<td style={{ textAlign: 'right' }}><FormattedNumber value={savingRecordTotals?.premiumTotal || 0} style="currency" currency="EUR" /></td>
								</tr>
								<tr>
									<td style={{ paddingRight: '8px', borderTop: '1px solid var(--col-orange-500)' }}><b>Totaal:</b></td>
									<td style={{ borderTop: '1px solid var(--col-orange-500)', textAlign: 'right' }}><FormattedNumber value={(savingRecordTotals?.wageTotal + savingRecordTotals?.premiumTotal) || 0} style="currency" currency="EUR" /></td>
								</tr>
							</tbody>
						</table>
					</WarningBanner>
					<br />
				</div>}
				{((savingRecordTotals?.wageTotal + savingRecordTotals?.premiumTotal) > 300) && <div style={{ width: '100%', maxWidth: '500px' }}>
					<InfoBanner>
						Het saldo van deze persoon is als volgt<br />
						<table>
							<colgroup>
								<col width="1" />
								<col width="*" />
							</colgroup>
							<tbody>
								<tr>
									<td style={{ paddingRight: '8px' }}><b>Loon:</b></td>
									<td style={{ textAlign: 'right' }}><FormattedNumber value={savingRecordTotals?.wageTotal || 0} style="currency" currency="EUR" /></td>
								</tr>
								<tr>
									<td style={{ paddingRight: '8px' }}><b>Premie:</b></td>
									<td style={{ textAlign: 'right' }}><FormattedNumber value={savingRecordTotals?.premiumTotal || 0} style="currency" currency="EUR" /></td>
								</tr>
								<tr>
									<td style={{ paddingRight: '8px', borderTop: '1px solid var(--col-primary-500)' }}><b>Totaal:</b></td>
									<td style={{ borderTop: '1px solid var(--col-primary-500)', textAlign: 'right' }}><FormattedNumber value={(savingRecordTotals?.wageTotal + savingRecordTotals?.premiumTotal) || 0} style="currency" currency="EUR" /></td>
								</tr>
							</tbody>
						</table>
					</InfoBanner>
					<br />
				</div>}
			</div>}

			<ButtonArea>
				<ValidatedButton
					level="primary"
					type="submit"
					validations={[
						{ valid: financialApprovalStatus != null, error: 'Vul HR-goedkeuringsstatus in.' },
					]}>
					<span className="fa fa-check" />&nbsp; Opslaan
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
				<div style={{ flex: 1 }} />
			</ButtonArea>
		</Form>
	</Modal>;
}