import ConfigContext from '../context/ConfigContext';
import { ConfigFullContext } from '../context/ConfigFullContext';
import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

export default function ConfigInjector({ children }) {
	const [ config, setConfig ] = useState();

	useEffect(() => {
		fetch('/api/config/public')
			.then(r => r.json())
			.then(cfg => setConfig(cfg));
	}, []);

	const fullConfigQuery = useQuery(gql`{
		configuration {
			appName,
			themeColor,
			allowSelfApproval,
			dualAbsenceApproval
		}
	}`);

	return <ConfigContext.Provider value={config}>
		<ConfigFullContext.Provider value={fullConfigQuery?.data?.configuration}>
			{config != null && children}
		</ConfigFullContext.Provider>
	</ConfigContext.Provider>;
}