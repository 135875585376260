import { gql, useMutation, useQuery } from '@apollo/client';
import TopBar from '../TopBar';
import { Button, LoadingOverlay } from '@atrocit/scl';
import { AbsenceType } from '../absence/AbsenceType';
import { DateTime } from 'luxon';
import { FormattedDate } from 'react-intl';
import React, { useEffect, useState } from 'react';
import AbsenceFinancialApprovalModal from '../absence/AbsenceFinancialApprovalModal';
import { useConfiguration } from '../../hooks/useConfiguration';
import { useNavigate } from 'react-router';

export default function AbsencesToApproveHR() {
	const cfg = useConfiguration();
	const nav = useNavigate();
	const [ editFinancialApprovalModalOpen, setEditFinancialApprovalModalOpen ] = useState(null);
	const query = useQuery(gql`{
        absencesToFinanciallyApprove {
	        id,
	        user {
		        id,
		        fullName,
		        dockWorkerRole {
					workbookNr,
                }
	        },
	        start,
	        end,
	        absenceStatus,
	        absenceType,
	        remark,
	        workerRequested,
	        cepaLinesForWeekend,
	        financialApprovalStatus,
        }
	}`);
	const absences = query?.data?.absencesToFinanciallyApprove ?? [];

	const [ setAbsenceFinancialApprovalStatusMutation ] = useMutation(gql`mutation Mutation($absenceId: Int!, $weekendLines: Boolean!, $financialApprovalStatus: AbsenceFinancialApprovalStatus!) {
        changeAbsenceFinancialApprovalStatus(absenceId: $absenceId, weekendLines: $weekendLines, financialApprovalStatus: $financialApprovalStatus) { 
	        id,
            financialApprovalStatus,
        }
    }`);

	useEffect(() => {
		if (cfg != null && !cfg.dualAbsenceApproval) {
			nav('/absences/calendar');
		}
	}, [ cfg.dualAbsenceApproval ]);

	return <>
		{editFinancialApprovalModalOpen != null && <AbsenceFinancialApprovalModal absenceId={editFinancialApprovalModalOpen} onClose={() => setEditFinancialApprovalModalOpen(null)} />}
		<TopBar title="HR Absenties goed te keuren" />
		<div className="grey-page-bg">
			<div className="page">
				{query.loading && <LoadingOverlay />}

				<br /><br />
				<table className="table table-fw">
					<colgroup>
						<col width="41" />
						<col width="77" />
						<col width="20" />
						<col width="32" />
						<col width="22" />
						<col width="230" />
						<col width="200" />
						<col width="150" />
						<col width="150" />
						<col width="*" />
					</colgroup>
					<thead>
						<tr>
							<th />
							<th />
							<th title="Aangevraagd door arbeider"><span className="fa fa-user" /></th>
							<th title="CEPA-regels voor weekenden">C.w.</th>
							<th title="Ceelbaas goedkeuring"><span className="fa fa-question-circle" /></th>
							<th>Arbeider</th>
							<th>Type</th>
							<th>Van</th>
							<th>Tot</th>
							<th>Opmerking</th>
						</tr>
					</thead>
					<tbody>
						{absences.map(a => <tr key={a.id}>
							<td className="tbl-center"><Button onClick={() => setEditFinancialApprovalModalOpen(a.id)} ><span className="fa fa-pencil" /></Button></td>
							<td className="tbl-center">
								{a.financialApprovalStatus == 'APPROVED' && <span className="fa fa-check fa-check-circle green" />}
								{a.financialApprovalStatus == 'DENIED' && <span className="fa fa-times-circle red" />}
								{a.financialApprovalStatus == 'UNDECIDED' && <div style={{ display: 'flex', gap: 'var(--u-4)' }}>
									<Button
										onClick={() => {
											setAbsenceFinancialApprovalStatusMutation({
												variables: {
													absenceId: a.id,
													weekendLines: a.cepaLinesForWeekend,
													financialApprovalStatus: 'APPROVED',
												},
											});
										}}>
										<span className="fa fa-check" />
									</Button>
									<Button
										onClick={() => {
											setAbsenceFinancialApprovalStatusMutation({
												variables: {
													absenceId: a.id,
													weekendLines: a.cepaLinesForWeekend,
													financialApprovalStatus: 'DENIED',
												},
											});
										}}>
										<span className="fa fa-times" />
									</Button>
								</div>}
							</td>
							<td>{a.workerRequested && <span className="fa fa-user" title="Aangevraagd door arbeider" />}</td>
							<td className="tbl-center"><span className={a.cepaLinesForWeekend ? 'fa fa-check fa-check-circle grey' : 'fa fa-times-circle grey'} /></td>
							<td>
								{a.absenceStatus == 'REQUESTED' && <span className={cfg.dualAbsenceApproval ? "fa fa-circle grey" : "fa fa-question-circle grey"} style={cfg.dualAbsenceApproval ? { color: 'var(--col-grey-500)' } : {}} title="Aangevraagd" />}
								{a.absenceStatus == 'APPROVED' && <span className={cfg.dualAbsenceApproval ? "fa fa-circle" : "fa fa-check-circle green"} style={cfg.dualAbsenceApproval ? { color: 'var(--col-primary-600)' } : {}} title="Goedgekeurd" />}
								{a.absenceStatus == 'DENIED' && <span className={cfg.dualAbsenceApproval ? "fa fa-circle red" : "fa fa-times-circle red"} title="Afgewezen" />}
							</td>
							<td>{a.user?.fullName} <span title={'Werkboeknummer: ' + a.user?.dockWorkerRole?.workbookNr} className="diff-old"> {a.user?.dockWorkerRole?.workbookNr} </span></td>
							<td><AbsenceType type={a.absenceType} /></td>
							<td><FormattedDate value={DateTime.fromISO(a.start).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="short" /></td>
							<td><FormattedDate value={DateTime.fromISO(a.end).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="short" /></td>
							<td>{a.remark}</td>
						</tr>)}
						{absences.length == 0 && <tr>
							<td colSpan={10}>Geen resultaten</td>
						</tr>}
					</tbody>
				</table>
			</div>
		</div>
	</>;
}