import { Button, Checkbox, Form, InfoBanner, InputField, LoadingOverlay, Modal, WarningBanner, WithLabel } from '@atrocit/scl';
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import DateTimeField from '../lib/forms/DateTimeField';
import ButtonArea from '../lib/forms/ButtonArea';
import ValidatedButton from '../lib/forms/ValidatedButton';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import UserSelectField from '../lib/selectfields/UserSelectField';
import DateField from '../lib/forms/DateField';
import SelectField from '../lib/selectfields/SelectField';
import { FormattedNumber } from 'react-intl';
import { useConfiguration } from '../../hooks/useConfiguration';

export default function AddEditAbsenceModal({ absenceId, onClose }) {
	const cfg = useConfiguration();
	const [ user, setUser ] = useState(null);
	const [ startDate, setStartDate ] = useState(DateTime.now().startOf('day').startOf('day'));
	const [ endDate, setEndDate ] = useState(DateTime.now().startOf('day').endOf('day'));
	const [ cepaLinesForWeekend, setCepaLinesForWeekend ] = useState(false);
	const [ absenceStatus, setAbsenceStatus ] = useState('REQUESTED');
	const [ absenceType, setAbsenceType ] = useState('');
	const [ remark, setRemark ] = useState('');
	const [ workerRequested, setWorkerRequested ] = useState(false);

	const [ getAbsence, { loadingQuery } ] = useLazyQuery(gql`query Query($id: Int!) {
		absence(id: $id) {
			id, user { id }, start, end, cepaLinesForWeekend, absenceStatus, absenceType, remark, workerRequested
		}
	}`);

	const [ addAbsenceMutation, { loading: loadingMutation } ] = useMutation(gql`mutation Mutation($user: Int!, $start: Instant!, $end: Instant!, $weekendLines: Boolean!, $absenceStatus: AbsenceStatus!, $absenceType: AbsenceType!, $remark: String) {
		addAbsence(userId: $user, start: $start, end: $end, weekendLines: $weekendLines, status: $absenceStatus, absenceType: $absenceType, remark: $remark) { id }
	}`);

	const [ changeAbsenceMutation, { loading: loadingMutation2 } ] = useMutation(gql`mutation Mutation($aid: Int!, $user: Int!, $start: Instant!, $end: Instant!, $weekendLines: Boolean!, $absenceStatus: AbsenceStatus!, $absenceType: AbsenceType!, $remark: String) {
        changeAbsence(absenceId: $aid, userId: $user, start: $start, end: $end, weekendLines: $weekendLines, status: $absenceStatus, absenceType: $absenceType, remark: $remark) { id }
    }`);

	const [ deleteAbsenceMutation, { loading: loadingMutation3 } ] = useMutation(gql`mutation Mutation($aid: Int!) {
        deleteAbsence(absenceId: $aid)
    }`);

	const [ denyAbsenceMutation, { loading: loadingMutation4 } ] = useMutation(gql`mutation Mutation($aid: Int!) {
        denyAbsence(absenceId: $aid) { id } 
    }`);

	const savingRecordTotalsQuery = useQuery(gql`query Query($userId: Int) {
        savingRecordTotals(userId: $userId, includeUnapproved: true) {
            wageTotal,
            premiumTotal
        }
    }`, { variables: { userId: user } });
	const savingRecordTotals = savingRecordTotalsQuery?.data?.savingRecordTotals ?? {};

	useEffect(() => {
		if (absenceId == null) return;
		getAbsence({ variables: { id: absenceId } })
			.then(({ data }) => {
				const absence = data?.absence;
				setUser(absence.user?.id);
				setAbsenceStatus(absence?.absenceStatus);
				setStartDate(DateTime.fromISO(absence.start));
				setEndDate(DateTime.fromISO(absence.end));
				setCepaLinesForWeekend(absence.cepaLinesForWeekend);
				setAbsenceType(absence.absenceType);
				setRemark(absence.remark);
				setWorkerRequested(absence.workerRequested);
			});
	}, [ absenceId ]);

	return <Modal isOpen={true} title={absenceId == null ? 'Absentie registreren' : 'Absentie bewerken'} onRequestClose={onClose}>
		<Form onSubmit={() => {
			if (absenceId == null) {
				addAbsenceMutation({
					variables: {
						user,
						start: startDate.toUTC().toISO(),
						end: endDate.toUTC().toISO(),
						weekendLines: cepaLinesForWeekend,
						absenceStatus,
						absenceType,
						remark,
					},
				}).finally(onClose);
			} else {
				changeAbsenceMutation({
					variables: {
						aid: absenceId,
						user,
						start: startDate.toUTC().toISO(),
						end: endDate.toUTC().toISO(),
						weekendLines: cepaLinesForWeekend,
						absenceStatus,
						absenceType,
						remark,
					},
				}).finally(onClose);
			}
		}}>
			{(loadingQuery || loadingMutation || loadingMutation2 || loadingMutation3 || loadingMutation4) && <LoadingOverlay />}

			<WithLabel label="Wie">
				<UserSelectField onChange={setUser} value={user} disabled={workerRequested} />
			</WithLabel>

			<WithLabel label="Van">
				<DateField
					onChange={d => {
						const target = d?.startOf('day');
						setStartDate(target);
						if (target != null && endDate != null && target > endDate) {
							setEndDate(target.endOf('day'));
						}
					}}
					value={startDate} />
			</WithLabel>

			<WithLabel label="Tot">
				<DateField
					onChange={d => {
						const target = d?.endOf('day');
						setEndDate(target);
						if (target != null && startDate != null && target < startDate) {
							setStartDate(target.startOf('day'));
						}
					}}
					value={endDate} />
			</WithLabel>

			<WithLabel label="CEPA-regels voor weekenden">
				<Checkbox onChange={setCepaLinesForWeekend} value={cepaLinesForWeekend} />
			</WithLabel>

			<WithLabel label="Status">
				<SelectField
					options={[
						{ value: 'REQUESTED', label: <>&nbsp;<span className={cfg.dualAbsenceApproval ? "fa fa-circle grey" : "fa fa-question-circle grey"} style={cfg.dualAbsenceApproval ? { color: 'var(--col-grey-500)' } : {}} />&nbsp;Aangevraagd</> },
						{ value: 'APPROVED', label: <>&nbsp;<span className={cfg.dualAbsenceApproval ? "fa fa-circle" : "fa fa-check-circle green"} style={cfg.dualAbsenceApproval ? { color: 'var(--col-primary-600)' } : {}} />&nbsp;Goedgekeurd</> },
						{ value: 'DENIED', label: <>&nbsp;<span className={cfg.dualAbsenceApproval ? "fa fa-circle red" : "fa fa-times-circle red"} />&nbsp;Afgewezen</> },
					]}
					onChange={newValue => setAbsenceStatus(newValue?.value)}
					value={absenceStatus} />
			</WithLabel>

			<WithLabel label="Type">
				<SelectField
					placeholder="Absentietype selecteren..."
					options={[
						{ value: 'VACATION', label: 'Vakantie' },
						{ value: 'SICK_LEAVE', label: 'Ziekte' },
						{ value: 'SENIORITY', label: 'Anciënniteit' },
						{ value: 'TIME_FOR_TIME', label: 'HVD' },
						{ value: 'SAVINGS_RECORD', label: 'Briefje' },
						{ value: 'UNEMPLOYMENT', label: 'DOP' },
						{ value: 'OFFICIAL_REASON', label: 'Attest' },
						{ value: 'PARENTAL_LEAVE', label: 'Ouderschap' },
						{ value: 'TIME_CREDIT', label: 'Tijdkrediet' },
						{ value: 'SPECIAL_LEAVE', label: 'Klein verlet' },
						{ value: 'PARTTIME_3_5', label: '3/5' },
						{ value: 'PARTTIME_4_5', label: '4/5' },
						{ value: 'OTHER', label: 'Overig' },
						// { value: 'UNKNOWN', label: 'Onbekend' },
					]}
					onChange={newValue => setAbsenceType(newValue?.value)}
					value={absenceType} />
			</WithLabel>

			{absenceType == 'SAVINGS_RECORD' && <div style={{ maxWidth: '80vh' }}>
				{((savingRecordTotals?.wageTotal + savingRecordTotals?.premiumTotal) <= 300) && <div style={{ width: '100%', maxWidth: '500px' }}>
					<WarningBanner>
						Het saldo van deze persoon is laag<br />
						<table>
							<colgroup>
								<col width="1" />
								<col width="*" />
							</colgroup>
							<tbody>
								<tr>
									<td style={{ paddingRight: '8px' }}><b>Loon:</b></td>
									<td style={{ textAlign: 'right' }}><FormattedNumber value={savingRecordTotals?.wageTotal || 0} style="currency" currency="EUR" /></td>
								</tr>
								<tr>
									<td style={{ paddingRight: '8px' }}><b>Premie:</b></td>
									<td style={{ textAlign: 'right' }}><FormattedNumber value={savingRecordTotals?.premiumTotal || 0} style="currency" currency="EUR" /></td>
								</tr>
								<tr>
									<td style={{ paddingRight: '8px', borderTop: '1px solid var(--col-orange-500)' }}><b>Totaal:</b></td>
									<td style={{ borderTop: '1px solid var(--col-orange-500)', textAlign: 'right' }}><FormattedNumber value={(savingRecordTotals?.wageTotal + savingRecordTotals?.premiumTotal) || 0} style="currency" currency="EUR" /></td>
								</tr>
							</tbody>
						</table>
					</WarningBanner>
					<br />
				</div>}
				{((savingRecordTotals?.wageTotal + savingRecordTotals?.premiumTotal) > 300) && <div style={{ width: '100%', maxWidth: '500px' }}>
					<InfoBanner>
						Het saldo van deze persoon is als volgt<br />
						<table>
							<colgroup>
								<col width="1" />
								<col width="*" />
							</colgroup>
							<tbody>
								<tr>
									<td style={{ paddingRight: '8px' }}><b>Loon:</b></td>
									<td style={{ textAlign: 'right' }}><FormattedNumber value={savingRecordTotals?.wageTotal || 0} style="currency" currency="EUR" /></td>
								</tr>
								<tr>
									<td style={{ paddingRight: '8px' }}><b>Premie:</b></td>
									<td style={{ textAlign: 'right' }}><FormattedNumber value={savingRecordTotals?.premiumTotal || 0} style="currency" currency="EUR" /></td>
								</tr>
								<tr>
									<td style={{ paddingRight: '8px', borderTop: '1px solid var(--col-primary-500)' }}><b>Totaal:</b></td>
									<td style={{ borderTop: '1px solid var(--col-primary-500)', textAlign: 'right' }}><FormattedNumber value={(savingRecordTotals?.wageTotal + savingRecordTotals?.premiumTotal) || 0} style="currency" currency="EUR" /></td>
								</tr>
							</tbody>
						</table>
					</InfoBanner>
					<br />
				</div>}
			</div>}

			<WithLabel label="Toelichting">
				<InputField onChange={setRemark} value={remark} />
			</WithLabel>

			<ButtonArea>
				<ValidatedButton
					level="primary"
					type="submit"
					validations={[
						{ valid: absenceType != null && absenceType.length > 0, error: 'Vul type van de absentie in.' },
						{ valid: absenceStatus != null && absenceStatus.length > 0, error: 'Vul status van de absentie in.' },
						{ valid: user != null, error: 'Vul in over wie de absentie gaat.' },
						{ valid: startDate != null, error: 'Vul start van absentie in.' },
						{ valid: endDate != null, error: 'Vul eind van absentie in.' },
						{ valid: endDate > startDate, error: 'Einddatum moet na startdatum liggen.' },
					]}>
					<span className="fa fa-check" />&nbsp; Opslaan
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
				<div style={{ flex: 1 }} />
				{absenceId != null && <div title={workerRequested ? 'Deze aanvraag werd gedaan door een arbeider, dus het kan niet worden verwijderd.' : ''}><Button onClick={() => { deleteAbsenceMutation({ variables: { aid: absenceId } }).finally(onClose); }} disabled={workerRequested}><span className="fa fa-trash" /></Button></div>}
			</ButtonArea>
		</Form>
	</Modal>;
}