import TopBar from '../TopBar';
import PageTop from '../lib/ui/PageTop';
import React from 'react';
import screenshotGcal from '../../assets/screenshots/screenshot-google-calendar-redacted.png';
import screenshotOutlook1 from '../../assets/screenshots/outlook-calendar-1.png';
import screenshotOutlook2 from '../../assets/screenshots/outlook-calendar-2.png';
import { InputField, WithLabel } from '@atrocit/scl';
import { gql, useQuery } from '@apollo/client';

export default function CalendarSync() {
	const uniqueTokenQuery = useQuery(gql`query Query {
		uniqueToken
	}`);

	return <>
		<TopBar title="Agendakoppeling" />

		<div className="grey-page-bg">
			<div className="page">
				<PageTop />

				<div>
					<h1>URL</h1>
					<div>
						<WithLabel label="Agenda URL">
							<InputField value={window.location.origin + '/api/v1/public/calendar/' + uniqueTokenQuery?.data?.uniqueToken} />
						</WithLabel>
					</div>

					<h2>Instructies Google Calendar</h2>
					<ol>
						<li>Ga naar Google Calendar</li>
						<li>Klik bij <i>Andere Agenda's</i> op de plus-knop</li>
						<li>Kies <i>Via URL</i></li>
						<li>Kopieër bovenstaande URL in het veld en klik op de <i>Agenda toevoegen</i> knop</li>
					</ol>

					<img src={screenshotGcal} alt="Welke optie te selecteren om agenda in Google Calendar te laten zien" style={{ maxWidth: '500px', height: 'auto', width: '100%' }} />

					<h2>Instructies Outlook</h2>
					<ol>
						<li>Ga naar de Outlook Agenda-module</li>
						<li>Klik in de linkerbalk op <i>Agenda toevoegen</i></li>
						<li>Kies de optie <i>Abonneren via internet</i></li>
						<li>Kopieër bovenstaande URL in het veld en klik op <i>Importeren</i></li>

						<img src={screenshotOutlook1} alt="Welke optie in linkerbalk van Outlook te selecteren" style={{ maxWidth: '200px', height: 'auto', width: '100%', marginRight: 'var(--u-32)' }} />
						<img src={screenshotOutlook2} alt="Abonneren via internet in Outlook" style={{ maxWidth: '500px', height: 'auto', width: '100%' }} />
					</ol>
				</div>
			</div>
		</div>
	</>;
}