import { gql, useMutation, useQuery } from '@apollo/client';
import TopBar from '../TopBar';
import { Button, LoadingOverlay } from '@atrocit/scl';
import { AbsenceType } from '../absence/AbsenceType';
import { DateTime } from 'luxon';
import { FormattedDate } from 'react-intl';
import React from 'react';
import { useConfiguration } from '../../hooks/useConfiguration';

export default function AbsencesToApprove() {
	const cfg = useConfiguration();
	const query = useQuery(gql`{
        absencesToApprove {
	        id,
	        user {
		        id,
		        fullName,
		        dockWorkerRole {
					workbookNr,
                }
	        },
	        start,
	        end,
	        absenceStatus,
	        absenceType,
	        remark,
	        workerRequested,
            financialApprovalStatus,
        }
	}`);
	const absences = query?.data?.absencesToApprove ?? [];

	const [ approveAbsenceMutation ] = useMutation(gql`mutation Mutation($aid: Int!) {
		approveAbsence(absenceId: $aid) {
			id,
			absenceStatus,
		}
    }`);

	const [ denyAbsenceMutation ] = useMutation(gql`mutation Mutation($aid: Int!) {
		denyAbsence(absenceId: $aid) {
			id,
			absenceStatus,
		}
    }`);

	return <>
		<TopBar title="Absenties goed te keuren" />
		<div className="grey-page-bg">
			<div className="page">
				{query.loading && <LoadingOverlay />}

				<br /><br />
				<table className="table table-fw">
					<colgroup>
						<col width="77" />
						{cfg.dualAbsenceApproval && <col width="26" />}
						<col width="20" />
						<col width="230" />
						<col width="200" />
						<col width="150" />
						<col width="150" />
						<col width="*" />
					</colgroup>
					<thead>
						<tr>
							<th />
							{cfg.dualAbsenceApproval && <th title="HR goedkeuring">HR</th>}
							<th title="Aangevraagd door arbeider"><span className="fa fa-user" /></th>
							<th>Arbeider</th>
							<th>Type</th>
							<th>Van</th>
							<th>Tot</th>
							<th>Opmerking</th>
						</tr>
					</thead>
					<tbody>
						{absences.map(a => <tr key={a.id}>
							<td className="tbl-center">
								{a.absenceStatus == 'APPROVED' && <span className={cfg.dualAbsenceApproval ? "fa fa-circle" : "fa fa-check-circle green"} style={cfg.dualAbsenceApproval ? { color: 'var(--col-primary-600)' } : {}} />}
								{a.absenceStatus == 'DENIED' && <span className={cfg.dualAbsenceApproval ? "fa fa-circle red" : "fa fa-times-circle red"} />}
								{a.absenceStatus == 'REQUESTED' && <div style={{ display: 'flex', gap: 'var(--u-4)' }}>
									<Button
										onClick={() => {
											approveAbsenceMutation({
												variables: {
													aid: a.id,
												},
											});
										}}>
										<span className="fa fa-check" />
									</Button>
									<Button
										onClick={() => {
											denyAbsenceMutation({
												variables: {
													aid: a.id,
												},
											});
										}}>
										<span className="fa fa-times" />
									</Button>
								</div>}
							</td>
							{cfg.dualAbsenceApproval && <td className="tbl-center">
								{a.financialApprovalStatus == 'APPROVED' && <span className="fa fa-check-circle green"/>}
								{a.financialApprovalStatus == 'DENIED' && <span className="fa fa-times-circle red"/>}
							</td>}
							<td>{a.workerRequested && <span className="fa fa-user" title="Aangevraagd door arbeider" />}</td>
							<td>{a.user?.fullName} <span title={'Werkboeknummer: ' + a.user?.dockWorkerRole?.workbookNr} className="diff-old"> {a.user?.dockWorkerRole?.workbookNr} </span></td>
							<td><AbsenceType type={a.absenceType} /></td>
							<td><FormattedDate value={DateTime.fromISO(a.start).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="short" /></td>
							<td><FormattedDate value={DateTime.fromISO(a.end).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="short" /></td>
							<td>{a.remark}</td>
						</tr>)}
						{absences.length == 0 && <tr>
							<td colSpan={8}>Geen resultaten</td>
						</tr>}
					</tbody>
				</table>
			</div>
		</div>
	</>;
}