import { useMemo } from 'react';
import { groupBy } from 'lodash';
import { FormattedDate, FormattedTime } from 'react-intl';
import { AbsenceType } from './AbsenceType';
import { DateTime } from 'luxon';
import { DEFAULT_SPECIAL_DAY_COLOR } from './specialdays/SpecialDaysOverview';
import { useConfiguration } from '../../hooks/useConfiguration';

export default function AbsenceWeek({ specialDays, absences, start, onEdit, showDeniedAbsences }) {
	const absThisWeek = absences.filter(abs => abs.end >= start && abs.start < start.plus({ weeks: 1 }));
	const absByUsers = useMemo(() => {
		const usersById = absThisWeek.reduce((users, abs) => ({ ...users, [abs.user?.id]: abs.user }), {});
		const groupedAbsences = groupBy(absThisWeek, a => a.user?.id);

		return Object.keys(groupedAbsences)
			.map(userId => ({
				absences: groupedAbsences[userId],
				user: usersById[userId],
			}))
			.sort((a, b) => a.user.fullName.localeCompare(b.user.fullName));
	}, [ absences ]);

	// This constant holds a list of lists, where for every day this week there is a list of absences for that day
	const absencesPerDayThisWeek = Array.from({ length: 7 }, (_, i) => {
		const usersAbsentToday = new Set();

		return absThisWeek.filter(a => {
			const isWithinDay = a.end >= start.plus({ days: i }) && a.start < start.plus({ days: i + 1 });

			if (isWithinDay && !usersAbsentToday.has(a.user)) { // check if this user is already absent once today
				usersAbsentToday.add(a.user);
				return true;
			}

			return false;
		});
	});

	return <div className="abs-week">
		<div className="abs-week-row abs-week-header">
			<div />
			<div><FormattedDate value={start} weekday="long" /><br /><FormattedDate value={start} day="2-digit" month="2-digit" /><ShowSpecialDays specialDays={specialDays} date={start} /></div>
			<div><FormattedDate value={start.plus({ days: 1 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 1 })} day="2-digit" month="2-digit" /><ShowSpecialDays specialDays={specialDays} date={start.plus({ days: 1 })} /></div>
			<div><FormattedDate value={start.plus({ days: 2 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 2 })} day="2-digit" month="2-digit" /><ShowSpecialDays specialDays={specialDays} date={start.plus({ days: 2 })} /></div>
			<div><FormattedDate value={start.plus({ days: 3 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 3 })} day="2-digit" month="2-digit" /><ShowSpecialDays specialDays={specialDays} date={start.plus({ days: 3 })} /></div>
			<div><FormattedDate value={start.plus({ days: 4 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 4 })} day="2-digit" month="2-digit" /><ShowSpecialDays specialDays={specialDays} date={start.plus({ days: 4 })} /></div>
			<div><FormattedDate value={start.plus({ days: 5 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 5 })} day="2-digit" month="2-digit" /><ShowSpecialDays specialDays={specialDays} date={start.plus({ days: 5 })} /></div>
			<div><FormattedDate value={start.plus({ days: 6 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 6 })} day="2-digit" month="2-digit" /><ShowSpecialDays specialDays={specialDays} date={start.plus({ days: 6 })} /></div>
		</div>
		{/* eslint-disable-next-line no-shadow */}
		{absByUsers.map(({ absences, user }) => <AbsenceWeekUser key={user?.id} user={user} absences={absences} start={start} onEdit={onEdit}/>)}
		<div className="abs-week-row abs-week-footer">
			<div />
			<div><FormattedDate value={start} weekday="long" /><br /><FormattedDate value={start} day="2-digit" month="2-digit" /></div>
			<div><FormattedDate value={start.plus({ days: 1 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 1 })} day="2-digit" month="2-digit" /></div>
			<div><FormattedDate value={start.plus({ days: 2 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 2 })} day="2-digit" month="2-digit" /></div>
			<div><FormattedDate value={start.plus({ days: 3 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 3 })} day="2-digit" month="2-digit" /></div>
			<div><FormattedDate value={start.plus({ days: 4 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 4 })} day="2-digit" month="2-digit" /></div>
			<div><FormattedDate value={start.plus({ days: 5 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 5 })} day="2-digit" month="2-digit" /></div>
			<div><FormattedDate value={start.plus({ days: 6 })} weekday="long" /><br /><FormattedDate value={start.plus({ days: 6 })} day="2-digit" month="2-digit" /></div>
		</div>
		<div className="abs-week-row abs-week-footer-lower">
			<div style={{ textAlign: 'left' }}>Absenties aangevraagd:</div>
			<div>{absencesPerDayThisWeek[0].filter(a => a.absenceStatus == "REQUESTED").length}</div>
			<div>{absencesPerDayThisWeek[1].filter(a => a.absenceStatus == "REQUESTED").length}</div>
			<div>{absencesPerDayThisWeek[2].filter(a => a.absenceStatus == "REQUESTED").length}</div>
			<div>{absencesPerDayThisWeek[3].filter(a => a.absenceStatus == "REQUESTED").length}</div>
			<div>{absencesPerDayThisWeek[4].filter(a => a.absenceStatus == "REQUESTED").length}</div>
			<div>{absencesPerDayThisWeek[5].filter(a => a.absenceStatus == "REQUESTED").length}</div>
			<div>{absencesPerDayThisWeek[6].filter(a => a.absenceStatus == "REQUESTED").length}</div>
		</div>
		<div className="abs-week-row abs-week-footer-lower">
			<div style={{ textAlign: 'left' }}>Absenties goedgekeurd:</div>
			<div>{absencesPerDayThisWeek[0].filter(a => a.absenceStatus == "APPROVED").length}</div>
			<div>{absencesPerDayThisWeek[1].filter(a => a.absenceStatus == "APPROVED").length}</div>
			<div>{absencesPerDayThisWeek[2].filter(a => a.absenceStatus == "APPROVED").length}</div>
			<div>{absencesPerDayThisWeek[3].filter(a => a.absenceStatus == "APPROVED").length}</div>
			<div>{absencesPerDayThisWeek[4].filter(a => a.absenceStatus == "APPROVED").length}</div>
			<div>{absencesPerDayThisWeek[5].filter(a => a.absenceStatus == "APPROVED").length}</div>
			<div>{absencesPerDayThisWeek[6].filter(a => a.absenceStatus == "APPROVED").length}</div>
		</div>
		{showDeniedAbsences && <div className="abs-week-row abs-week-footer-lower">
			<div style={{ textAlign: 'left' }}>Absenties afgewezen:</div>
			<div>{absencesPerDayThisWeek[0].filter(a => a.absenceStatus == "DENIED").length}</div>
			<div>{absencesPerDayThisWeek[1].filter(a => a.absenceStatus == "DENIED").length}</div>
			<div>{absencesPerDayThisWeek[2].filter(a => a.absenceStatus == "DENIED").length}</div>
			<div>{absencesPerDayThisWeek[3].filter(a => a.absenceStatus == "DENIED").length}</div>
			<div>{absencesPerDayThisWeek[4].filter(a => a.absenceStatus == "DENIED").length}</div>
			<div>{absencesPerDayThisWeek[5].filter(a => a.absenceStatus == "DENIED").length}</div>
			<div>{absencesPerDayThisWeek[6].filter(a => a.absenceStatus == "DENIED").length}</div>
		</div>}
	</div>;
}

export function ShowSpecialDays({ specialDays, date }) {
	return <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--u-2)' }}>
		{specialDays.filter(sd => DateTime.fromISO(sd.date) >= date.startOf('day') && DateTime.fromISO(sd.date) < date.endOf('day')).map(sd => <div key={sd.id} className="abs-special-day" style={{ background: sd.colorCode || DEFAULT_SPECIAL_DAY_COLOR }}>{sd.name}</div>)}
	</div>;
}

function AbsenceWeekUser({ user, absences, start, onEdit }) {
	return <div className="abs-week-row">
		<div>{user?.fullName} <span title={'Werkboeknummer: ' + user?.dockWorkerRole?.workbookNr} className="diff-old"> {user?.dockWorkerRole?.workbookNr} </span></div>
		<div>
			<AbsenceWeekDay absences={absences} start={start} startOfWeek={true} onEdit={onEdit} />
		</div>
		<div>
			<AbsenceWeekDay absences={absences} start={start.plus({ days: 1 })} onEdit={onEdit} />
		</div>
		<div>
			<AbsenceWeekDay absences={absences} start={start.plus({ days: 2 })} onEdit={onEdit} />
		</div>
		<div>
			<AbsenceWeekDay absences={absences} start={start.plus({ days: 3 })} onEdit={onEdit} />
		</div>
		<div>
			<AbsenceWeekDay absences={absences} start={start.plus({ days: 4 })} onEdit={onEdit} />
		</div>
		<div>
			<AbsenceWeekDay absences={absences} start={start.plus({ days: 5 })} onEdit={onEdit} />
		</div>
		<div>
			<AbsenceWeekDay absences={absences} start={start.plus({ days: 6 })} onEdit={onEdit} endOfWeek={true} />
		</div>
	</div>;
}

function AbsenceItem({ absence, start, onEdit }) {
	const cfg = useConfiguration();
	const isStart = absence.start >= start && absence.start < start.plus({ days: 1 });
	const isEnd = absence.end > start && absence.end <= start.plus({ days: 1 });
	const isWeekend = start.weekday == 6 || start.weekday == 7;

	return <div className={"abs-item" + (isStart ? ' abs-item-start' : '') + (isEnd ? ' abs-item-end' : '') + (absence.absenceStatus == 'APPROVED' ? '' : (absence.absenceStatus == 'DENIED' ? ' abs-denied' : ' abs-unsure')) + (isWeekend && !absence.cepaLinesForWeekend ? ' not-cepa-weekend' : '')} onDoubleClick={() => onEdit(absence.id)}>
		<div className="abs-time">{isStart && absence.start > start && <FormattedTime value={absence.start} />}</div>
		<div style={{ display: 'flex', gap: 'var(--u-4)' }}>
			<div className="absence-status">
				{cfg.dualAbsenceApproval
					? <>
						{absence.workerRequested && <><span className="fa fa-user" title="Aangevraagd door arbeider" />&nbsp;</>}
						{absence.financialApprovalStatus == 'UNDECIDED' && <span className="fa fa-question-circle" title="HR Onbeslist" />}
						{absence.financialApprovalStatus == 'APPROVED' && <span className="fa fa-check-circle green" title="Goedgekeurd door HR" />}
						{absence.financialApprovalStatus == 'DENIED' && <span className="fa fa-times-circle" style={absence.absenceStatus == 'DENIED' ? { color: 'var(--col-red-300)' } : { color: 'var(--col-red-500)' }} title="Afgewezen door HR" />}
					</>
					: <>
						{absence.workerRequested && <><span className="fa fa-user" title="Aangevraagd door arbeider" />&nbsp;</>}
						{absence.absenceStatus == 'REQUESTED' && <span className="fa fa-question-circle" title="Aangevraagd" />}
						{absence.absenceStatus == 'APPROVED' && <span className="fa fa-check-circle" title="Goedgekeurd" />}
						{absence.absenceStatus == 'DENIED' && <span className="fa fa-times-circle" title="Afgewezen" />}
					</>
				}
			</div>
			<div className="absence-remark">
				<AbsenceType type={absence.absenceType} />{(absence.remark != null && absence.remark.trim().length > 0) ? <> - {absence.remark}</> : null}
			</div>
		</div>
		<div className="abs-time" style={{ textAlign: 'right' }}>{isEnd && absence.end < start.plus({ days: 1 }).minus({ seconds: 60 }) && <FormattedTime value={absence.end} />}</div>
	</div>;
}



export function AbsenceWeekDay({ absences, start, startOfWeek, endOfWeek, onEdit }) {
	return <div className={"abs-week-day" + (startOfWeek ? ' abs-week-day-first' : '') + (endOfWeek ? ' abs-week-day-last' : '')}>
		{absences.filter(a => a.start < start.plus({ days: 1 }) && a.end > start).map(a => <AbsenceItem key={a.id} absence={a} start={start} onEdit={onEdit} />)}
	</div>;
}